import Layout from '../components/Layout'

import contact from '../images/consulting/contact.png'
import email from '../images/consulting/email.png'
import local from '../images/consulting/local.png'

const Consulting: React.FC = () => {
  return (
    <Layout className="bg-[#f5fafe] sm:bg-[linear-gradient(360deg,#FFF_0%,#EAF4FC_100%)]">
      <section className="w-full bg-[url('../images/consulting/bgBanner.png')] bg-[length:100%_517px] bg-top bg-no-repeat font-regularText sm:bg-[url('../images/consulting/mobileBanner.png')] md:bg-[length:auto_50%] sm:bg-[length:100%_499px]">
        <div className="pt-20 mx-auto bg-transparent max-w-7xl md:px-16 sm:px-4 sm:pt-[57px]">
          <div className="mt-[114px] text-[34px] leading-[52px] font-boldText font-bold text-center sm:mt-[57px]">
            Consulting
          </div>
          <div className="mt-3 text-sm text-center">
            We are committed to providing blockchain services. Please consult us
            online
          </div>
          <div className="mt-[81px] bg-[rgba(255,255,255,0.75)] rounded-2xl pt-[52px] pb-[54px] px-[38px] grid grid-cols-2 gap-[42px] sm:mt-[41px] sm:pt-6 sm:px-5 sm:grid-cols-1">
            <div>
              <div className="text-[34px] font-boldText font-bold leading-[52px]">
                Contact us
              </div>
              <div className="mt-3 text-sm sm:mt-2">
                Thank you for your interest in ChainTokenomics. Please fill out
                the form below to ask a question or report a technical problem.
              </div>
              <div className="text-sm">
                {[
                  { icon: contact, info: 'Tel. +41 44 261 13 56' },
                  { icon: email, info: 'mail@aebdibauleitung.ch' },
                  {
                    icon: local,
                    info: 'Ocean Protocol Foundation Ltd. The Commerze @ Irving 1 Irving Place, #08-11 369546 Singapore',
                  },
                ].map(x => (
                  <div className="flex items-center mt-10 first:sm:mt-8">
                    <img
                      src={x.icon}
                      alt="icon"
                      className="w-[42px] h-[42px]"
                    />
                    <div className="ml-5 text-[#333]">{x.info}</div>
                  </div>
                ))}
              </div>
            </div>
            <form>
              <div className="grid grid-cols-2 gap-5 md:grid-cols-1 md:gap-[42px]">
                <div>
                  <label
                    htmlFor="name"
                    className="relative text-sm sm:text-base"
                  >
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    id="name"
                    className="h-[52px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] mt-2 rounded"
                    placeholder="Your Name"
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="relative text-sm sm:text-base"
                  >
                    Phone number（optional）
                  </label>
                  <input
                    name="name"
                    type="text"
                    id="name"
                    className="h-[52px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] mt-2 rounded"
                    placeholder="Your phone number"
                  />
                </div>
              </div>
              <div className="mt-[42px]">
                <label
                  htmlFor="email"
                  className="relative text-sm sm:text-base"
                >
                  Email
                </label>
                <input
                  name="Email"
                  type="email"
                  id="email"
                  className="h-[52px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] mt-2 rounded"
                  placeholder="Your Email"
                />
              </div>
              <div className="mt-[42px]">
                <label
                  htmlFor="message"
                  className="relative text-sm sm:text-base"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  className="h-[137px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] mt-2 resize-none"
                  placeholder="Briefly describe your content"
                />
              </div>
              <button
                className="bg-[linear-gradient(315deg,#1E94FC_50%,#AED8FF_100%)] rounded w-[188px] h-[54px] text-white mt-[33px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] sm:w-full"
                onClick={e => {
                  e.preventDefault()
                  console.log('submit')
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Consulting
